import { FC, useState } from "react";
import {
  FormControl,
  FormLabel,
  HStack,
  IconButton,
  Square,
  Stack,
  Textarea,
  Wrap,
} from "@chakra-ui/react";
import { IoSend } from "react-icons/io5";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createOrderMessage } from "services/messageService";
import { orderMessagesKeys } from "settings/constants/queryKeys";
import PlusIcon from "icons/PlusIcon";
import useGalleryForm from "hooks/useGalleryForm";
import { Dropzone, FilePreview } from "hr-design-system";

interface Props {
  orderId: string;
}
const SendMessageSection: FC<Props> = ({ orderId }) => {
  const [messageText, setMessageText] = useState("");
  const queryClient = useQueryClient();
  const {
    uploadedFiles,
    handleRemoveAttachment,
    dropzoneDisclosure,
    handleFileUpload,
    handleSave,
  } = useGalleryForm({});

  const createMutation = useMutation({
    mutationFn: async () => {
      const { finalAttachments } = await handleSave();
      return createOrderMessage({
        resourceId: orderId,
        text: messageText,
        attachments: finalAttachments,
      });
    },
    onSuccess: () => {
      setMessageText("");
      queryClient.invalidateQueries({ queryKey: orderMessagesKeys(orderId) });
    },
  });

  return (
    <Stack>
      <FormControl>
        <FormLabel>Message</FormLabel>
        <Textarea
          value={messageText}
          onChange={(e) => setMessageText(e.currentTarget.value)}
          minH="8rem"
          placeholder="e.g., Could you please provide an update on the progress of the work?"
        />
      </FormControl>

      {/* Attachments */}
      <Wrap>
        {uploadedFiles.map((file) => (
          <Square key={file.id}>
            <FilePreview
              // width="full"
              // height="20"
              fileName={file.name}
              allowClickToEnlarge={true}
              fileURL={file.url}
              fileType={file.format}
              allowRemove={true}
              allowDownload={false}
              onRemove={() => handleRemoveAttachment(file.id)}
            />
          </Square>
        ))}
      </Wrap>

      <HStack justify="space-between">
        <IconButton
          rounded="full"
          aria-label="Upload attachments"
          icon={<PlusIcon />}
          colorScheme="blue"
          onClick={dropzoneDisclosure.onOpen}
        />
        <IconButton
          rounded="md"
          aria-label="Send Message"
          icon={<IoSend />}
          colorScheme="blue"
          isLoading={createMutation.isPending}
          onClick={() => createMutation.mutate()}
        />
      </HStack>

      {dropzoneDisclosure.isOpen && (
        <Dropzone
          acceptOnlyImages={false}
          wrapInModal={true}
          allowMultiple
          // hideUploadButton
          onUpload={(data) => console.log({ data })}
          onClose={dropzoneDisclosure.onClose}
          onFileUpload={handleFileUpload}
        />
      )}
    </Stack>
  );
};

export default SendMessageSection;
