/////////////////////
// ProjectsLayout
/////////////////////

// Basic Imports
import { FC } from "react";

// Custom Hook Imports

// Design Imports

// Layout and Section Imports
import useOrders from "hooks/useOrders";
import { useAuth } from "context/AuthProvider";
import DashboardLayout from "./DashboardLayout";
import { NavItemProps } from "App";
import { ROUTES } from "settings/constants/routes";

// Style Imports (al CSS files)

// Data Imports

// Interfaces

const ORDERS_NAV_ITEMS: NavItemProps[] = [
  { label: "Active", href: ROUTES.ORDERS_ACTIVE },
  { label: "Pending", href: ROUTES.ORDERS_PENDING },
  { label: "Completed", href: ROUTES.ORDERS_COMPLETED },
  { label: "Delivered", href: ROUTES.ORDERS_DELIVERED },
  { label: "Cancelled", href: ROUTES.ORDERS_CANCELLED },
];

const OrdersLayout: FC = () => {
  const { user } = useAuth();
  const { data: orders } = useOrders({ userId: user?.id! });

  const navItemsWithCounts = ORDERS_NAV_ITEMS?.map((item) => {
    const activeStatus = item.href.split("/")[2] || "in_progress"; // Extract status from "/orders/active" and if it "/orders" then default is Active named as in_progress
    const count = orders?.filter(
      (order) => activeStatus === order.status
    ).length;

    return { ...item, count };
  });

  return (
    <DashboardLayout
      title="Orders"
      isProjectContainer
      navItems={navItemsWithCounts}
      isFor="orders"
    />
  );
};

export default OrdersLayout;
