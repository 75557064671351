/////////////////////
// Notification (appPages) UI
/////////////////////

// Basic Imports

// Design Imports
import { Box, Center, CenterProps } from "@chakra-ui/react";
import { useLocalUser } from "hooks/useLocalUser";

import { GoBellFill } from "react-icons/go";
// Layout and Section Imports

// Data Imports

// Interfaces

// Functions
const NotificationBadge = (props: CenterProps) => (
  <Center
    bg="red.500"
    fontSize="xs"
    fontWeight="bold"
    position="absolute"
    rounded="lg"
    textAlign="center"
    top="-2px"
    color="white"
    insetEnd="0"
    right={{ base: "-4px", md: "-12px" }}
    w={{ base: "16px", md: "22px" }}
    h={{ base: "16px", md: "20px" }}
    {...props}
  />
);

//TODO: Replace fake notifications with real notifications (i.e. update account billing information when credit card is 1 month from expiring, or notification when project requires input)
interface NotificationProps extends CenterProps {
  count: number;
}
export const Notification = (props: NotificationProps) => {
  const [userType] = useLocalUser("userType", "buyer");
  return (
    <Center
      as="button"
      outline="0"
      h="8"
      position="relative"
      rounded="lg"
      _hover={{ bg: "whiteAlpha.200" }}
      _focus={{ shadow: "outline" }}
      {...props}
    >
      <NotificationBadge>{props.count}</NotificationBadge>
      <Box
        as={GoBellFill}
        fill={userType === "buyer" ? "black" : "white"}
        fontSize={{ base: "md", md: "lg" }}
      />
    </Center>
  );
};
