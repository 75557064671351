import { FC } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { Order, OrderActivity, OrderStatus } from "types/order.types";
import Alert from "components/features/Alert";
import DeliveryActivity from "./DeliveryActivity";
import RenderActivity from "./RenderActivity";
import SellerReviewActivity from "./SellerReviewActivity";
import OrderDeliveredAction from "./OrderAlerts/OrderDeliveredAction";
import OrderRevisionAction from "./OrderAlerts/OrderRevisionAction";
import OrderReviewForm from "./OrderAlerts/OrderReviewForm";
import RevisionActivity from "./RevisionActivity";
import BuyerReviewActivity from "./BuyerReviewActivity";

interface Props {
  orderId: string;
  orderStatus: OrderStatus;
  activities: Order["activities"];
  remainingRevisions: number | undefined;
}

const ActivitiesSection: FC<Props> = ({
  orderStatus,
  orderId,
  activities,
  remainingRevisions,
}) => {
  const buyerRating = activities.find(
    (activity) => activity.action === "customer_ratings"
  );

  if (activities.length === 0) {
    return (
      <Alert
        status="error"
        title="Activities not found!"
        description="This order doesn't have any activities to show."
      />
    );
  }

  return (
    <Stack w="full" spacing={6}>
      {orderStatus === "delivered" && (
        <Stack>
          <OrderDeliveredAction orderId={orderId} />
          <OrderRevisionAction
            orderId={orderId}
            remainingRevisions={remainingRevisions}
          />
        </Stack>
      )}
      {orderStatus === "completed" && !buyerRating && (
        <OrderReviewForm orderId={orderId} />
      )}
      <Card variant="outline" w="full">
        <CardHeader>
          <Heading size="lg" textTransform="capitalize">
            Activities
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack divider={<Divider />} spacing={4}>
            {activities.map((activity) => renderActivity(activity, orderId))}
          </Stack>
        </CardBody>
      </Card>
    </Stack>
  );
};

const renderActivity = (activity: OrderActivity, orderId: string) => {
  switch (activity.action) {
    case "delivery_created":
      return (
        <DeliveryActivity
          key={activity._id}
          orderId={orderId}
          activity={activity}
        />
      );
    case "order_started":
    case "order_completed":
      return <RenderActivity key={activity._id} activity={activity} />;
    case "customer_ratings":
      return (
        <BuyerReviewActivity
          key={activity._id}
          activity={activity}
          orderId={orderId}
        />
      );
    case "talent_ratings":
      return (
        <SellerReviewActivity
          key={activity._id}
          activity={activity}
          orderId={orderId}
        />
      );
    case "revision_requested":
      return (
        <RevisionActivity
          key={activity._id}
          activity={activity}
          orderId={orderId}
        />
      );
    default:
      return <RenderActivity key={activity._id} activity={activity} />;
  }
};
export default ActivitiesSection;
