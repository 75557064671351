import { FC } from "react";
import useReactTable from "hooks/useReactTable";
// import TableHeading from "../ProjectsSection/Table/TableHeading";
import {
  Alert,
  AlertIcon,
  HStack,
  Image,
  Table,
  Tbody,
  Td,
  Text,
  // Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Cell, Column, HeaderGroup, Row } from "react-table";
import RenderOrderStatus from "./RenderOrderStatus";
import { formatCurrency } from "utils/currency";
import { Link } from "react-router-dom";
import { Order } from "types/order.types";
import { ROUTES } from "settings/constants/routes";

const columns: Column<Order>[] = [
  {
    accessor: "profileId",
    Header: () => <Text>Talent</Text>,
    Cell: (props) => {
      const { value } = props;

      return (
        <HStack>
          <Image
            rounded="full"
            alt={value?.title || ""}
            src={value?.avatar || ""}
            w="10"
            h="10"
          />
          <Text>{value?.title || ""}</Text>
        </HStack>
      );
    },
  },
  {
    accessor: "title",
    Header: () => {
      return (
        <HStack>
          <Text>Offer</Text>
        </HStack>
      );
    },
    Cell: (props) => {
      const { value, cell } = props;
      const order = cell.row.original;

      return (
        <Link to={ROUTES.ORDER_PAGE(order.id, order.status)}>
          <Text _hover={{ textDecor: "underline" }} noOfLines={1}>
            {value}
          </Text>
        </Link>
      );
    },
  },
  {
    accessor: "totalAmount",
    Header: () => {
      // Header is wrapper on column (e.g., you can change text color, background-color etc)
      return <Text>Total</Text>;
    },
    Cell: (props) => {
      const { value } = props;
      return <Text>{formatCurrency(value)}</Text>;
    },
  },
  {
    accessor: "status",
    Header: () => <Text>Status</Text>,
    Cell: ({ value }) => <RenderOrderStatus status={value} />,
  },
];

interface Props {
  data: Order[];
}
const OrdersTable: FC<Props> = ({ data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useReactTable({ columnsData: columns, rowsData: data });

  return (
    <>
      {/* <TableHeading>{title}</TableHeading> */}
      <Table
        variant="striped"
        fontSize={{ base: "xs", lg: "md" }}
        p="4"
        {...getTableProps()}
      >
        <Thead>
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.length > 0 ? (
            rows.map((row: Row) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => {
                    return (
                      <Td border="none" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })
          ) : (
            <Tr>
              <Td colSpan={5}>
                <Alert status="info">
                  <AlertIcon />
                  Nothing to show
                </Alert>
              </Td>
            </Tr>
          )}
        </Tbody>
        {/* <Tfoot>
          {headerGroups.map((headerGroup: HeaderGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              ))}
            </Tr>
          ))}
        </Tfoot> */}
      </Table>
    </>
  );
};

export default OrdersTable;
