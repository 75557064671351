// interface IRoutes {
//   [propName: string]: string;
// }

import { OrderStatus } from "types/order.types";

// export const ROUTES: IRoutes = {
export const ROUTES = {
  // Dashboard Routes
  TALENT_PORTAL: "https://talent.homerender.io",
  DASHBOARD: "/dashboard",
  ANALYTICS: "/analytics",
  AUTOMATIONS: "/automations",
  // Project Routes
  PROJECTS: "/projects/all",
  PROJECTS_COMPLETED: "/projects/completed",
  PROJECTS_DRAFT: "/projects/draft",
  PROJECTS_PAUSED: "/projects/paused",
  PROJECTS_ACTIVE: "/projects",
  PROJECTS_PAGE: "/projects",
  PROJECTS_DELETED: "/projects/deleted",
  PROJECTS_NEW: "/projects/new-project",
  // Single Project Route
  PROJECT: "projects/:id",
  PROJECTS_WIZARD_ID: "/projects/new-project/:id",
  // Account Routes
  ACCOUNT: "/account",
  ACCOUNT_BILLING: "/account/billing",
  ACCOUNT_SECURITY: "/account/security",
  ACCOUNT_COMMUNICATIONS: "/account/communications",
  // Plan
  SELECT_PLAN: "/select-plan",
  HELP: "/help",
  // Auth
  LOGIN_IN: "/login",
  SIGN_UP: "/signup",
  FORGOT: "/forgot",
  ACCOUNT_VERIFY: "/account/verify",
  PASSWORD_RESET: "/account/password/reset",
  OFFERS: "/offers",

  //Talent
  OFFER_PAGE: (path: string) => "/offer/" + path,
  PROFILES: "/profiles",
  PROFILE_PAGE: (path: string) => "/profiles/" + path,

  // Orders
  ORDERS: "/orders",
  ORDERS_ACTIVE: "/orders",
  ORDERS_PENDING: "/orders/pending",
  ORDERS_COMPLETED: "/orders/completed",
  ORDERS_DELIVERED: "/orders/delivered",
  ORDERS_CANCELLED: "/orders/cancelled",

  // Order Page
  ORDER_PAGE: (orderId: string, orderStatus: OrderStatus) =>
    `/orders/${orderStatus}/${orderId}?tab=activity`,

  // Single Order Route
  ORDER_ID: "/orders/:status/:id",

  OFFER: "/offers/:offerId",

  PROFILE: "/profiles/:profileId",
};
