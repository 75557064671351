export const helpPageData = [
  {
    id: 0,
    name: "What is the minimum information required for creating a new project?",
    description:
      "There are two things typically required on each project 1) project name 2) blueprints, survey, drafts, sketch, or inspiration photos that you upload. These two items will form the basis of what we will design for you.",
  },
  {
    id: 1,
    name: "What are the types of assets that HomeRender can create?",
    description:
      "The purpose of HomeRender is to simplify the marketing process for Architects, Builders, Realtors, and Developers to generate images or graphically designed PDFs for social media, print brochure, listing sites, or their websites.",
  },
  {
    id: 2,
    name: "If I request for more than one desired asset, which one gets worked on first?",
    description:
      "We always progress from 2D to 3D. For example, a 2D draft is required to base the 3D model on so that it can be more dimensionally accurate. Or a landscaping plan can be created first to help with how exterior 3D renders will appear. ",
  },
  {
    id: 3,
    name: "What if I want to create something not on the asset list?",
    description:
      "Only Business Plan clients have the ability to create other assets, by selecting the Other option under Desired Assets step. Other assets could include websites and video.",
  },
  {
    id: 4,
    name: "I can download the source files?",
    description:
      "Yes, upon request, we can send you the source files. Since the files, especially for 3D, are often over 1GB, it takes time to prepare a copy and upload for you to download.",
  },
  {
    id: 5,
    name: "What kind of turnaround am I expecting?",
    description:
      "We cannot say for certain that a given asset will be completed by a certain time. This will depend on several factors including staffing, response time if we ask a question, and how detailed your feedback is.  Our rough estimates is that it takes 3-4 weeks to model (not render) a typical single family residence. Blueprints from scratch usually take 3 months. ",
  },
  {
    id: 6,
    name: "How soon will someone be able to work on my project?",
    description:
      "We have internal service level agreements that make progress on your project relatively quick (typically within 3 business days). This fluctuates with our staffing levels.",
  },
  {
    id: 7,
    name: "There are no limits to the number of drafts or renders that I can ask for?",
    description:
      "That's right, you can request an unlimited number of desired assets. This allows you to change the style, angles, lighting, or etc. The only warning is time allocation, if we're busy working on a single project's assets, your other projects will not be worked on unless you change the priority. Also, if everyone decides to generate more images than necessary, it slows down the whole system. So we recommend you to request just what you need. ",
  },
  {
    id: 8,
    name: "I have created multiple projects, which one gets worked on first? Can more than one be worked on at the same time?",
    description:
      "Only your Active Projects are visible to our staff. The project that has the highest priority, the project sitting at the top of this Active Project queue, is worked on. This project must be completed first, or the priorities changed, before we move on to other projects in your project queue. We can only guarantee that one project and one asset is worked on at one time. ",
  },
  {
    id: 9,
    name: "How do I change the priority of a project?",
    description:
      "On the left side of the project list, there is an icon with 3 horizontal lines (also called a hamburger menu icon), this icon can be dragged up or down to change the project's priority",
  },
  {
    id: 10,
    name: "Can I duplicate a past project?",
    description:
      "Yes, under Project Info > Project Description, you can just note that this project is based on another one that we've done in the past.",
  },
  {
    id: 11,
    name: "What is the point of completing the project?",
    description:
      "Completing a project is a method to organize projects. It creates a dedicated Completed Projects Download page for easily downloading the resulting marketing materials in one area. While the Active Projects showcases thumbnails of the assets in a gallery view, and you would have to scroll through a carousel of the various versions to find what you may be looking for.",
  },
  {
    id: 12,
    name: "What is the point of pausing the project?",
    description:
      "Pausing is common practice for homes that you've changed your mind with.  We keep the work that was already created in storage, and pull it back out when the project is resumed. A common example is designing a home intended for a piece of land, and then the land is sold. There is no reason to continue designing, but there is no point in deleting it either.",
  },
  {
    id: 13,
    name: "How long are deleted projects kept for?",
    description: "Typically deleted projects are kept for 30 days.",
  },
];
