import { FC } from "react";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Image,
  Skeleton,
  SlideFade,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";
import RenderOrderStatus from "../RenderOrderStatus";
import { Order } from "types/order.types";
import ChevronUpIcon from "components/ui/Icons/ChevronUpIcon";
import ChevronDownIcon from "components/ui/Icons/ChevronDownIcon";
import { formatCurrency } from "utils/currency";
import useOfferAttachments from "hooks/useOfferAttachments";
import PlaceholderImage from "components/sections/GigsSections/Image/PlaceholderImage";

interface Props {
  offer: Pick<Order["offerId"], "id" | "title">;
  order: Pick<Order, "status" | "totalAmount" | "maxRevisionsCount"> & {
    buyer: string;
    delivery: string | number | Date;
  };
}
const OrderDetailsSection: FC<Props> = ({ offer, order }) => {
  const { data: offerAttachments, isLoading } = useOfferAttachments(offer.id);
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const featuredAttachment = offerAttachments?.find(
    (at) => !!at.featured
  )?.cdnUrl;

  return (
    <Card>
      <CardHeader>
        <Stack spacing={4}>
          <HStack justify="space-between" onClick={onToggle}>
            <Text fontWeight="semibold">Order Details</Text>
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </HStack>
          <HStack userSelect="none">
            <Box
              rounded="md"
              overflow="hidden"
              position="relative"
              w="48"
              h="20"
              shadow="base"
            >
              {isLoading ? (
                <Skeleton w="full" h="full" />
              ) : featuredAttachment ? (
                <Image
                  alt={offer.title as any}
                  src={featuredAttachment}
                  w="full"
                  h="full"
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <PlaceholderImage />
              )}
            </Box>
            <Stack>
              <Text noOfLines={2}>{offer.title}</Text>
              <RenderOrderStatus status={order.status} />
            </Stack>
          </HStack>
        </Stack>
      </CardHeader>
      {isOpen && (
        <SlideFade in={true} offsetY="-20px">
          <CardBody>
            <Stack>
              <HStack justify="space-between">
                <Text>Talent</Text>
                <Text fontWeight="medium">{order.buyer}</Text>
              </HStack>
              <HStack justify="space-between">
                <Text>Delivery </Text>
                {order?.delivery ? (
                  <Text fontWeight="medium">
                    {format(new Date(order.delivery), "MMM dd, yyyy hh:mm a")}
                  </Text>
                ) : (
                  <Text fontWeight="medium">Unavailable</Text>
                )}
              </HStack>
              <HStack justify="space-between">
                <Text>Total price</Text>
                <Text fontWeight="medium">
                  {formatCurrency(order.totalAmount)}
                </Text>
              </HStack>
              {typeof order.maxRevisionsCount === "number" && (
                <HStack justify="space-between">
                  <Text>Remaining Revisions</Text>
                  <Text>{order.maxRevisionsCount}</Text>
                </HStack>
              )}
            </Stack>
          </CardBody>
        </SlideFade>
      )}
    </Card>
  );
};

export default OrderDetailsSection;
