/////////////////////
// Signup Form
/////////////////////

// Basic Imports
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Design Imports
import { Button, Center, Stack, StackProps } from "@chakra-ui/react";

import { GoogleIcon } from "../../../ui/common/ProviderIcons";

// import Alert from "components/features/Alert";

// Layout and Section Imports
import FormHeader from "../FormHeader";
import FormWrapper from "../FormWrapper";
import FormAlert from "../FormAlert";

// Data Imports
import { ROUTES } from "settings/constants/routes";
import { API_URL } from "settings/site-credentials";

// Custom Hooks and Services
import useAuthForm, { SignupFormTypes } from "../useAuthForm";
import FormInputField from "components/forms/ReactHookForm/FormInputField";
import { useState } from "react";
import ConsentCheckbox from "../ConsentCheckbox";
import TurnTileWidget from "pages/AuthPage/TurnsTile/TurnTile";
import RoleRadioButtons from "../RoleRadioButtons";

// Interfaces

// Functions

const schema = yup.object().shape({
  firstName: yup
    .string()
    .required()
    .max(50)
    .matches(/^[a-zA-Z]+$/, "First name can only contain letters")
    .label("First name"),
  lastName: yup
    .string()
    .required()
    .max(50)
    .matches(/^[a-zA-Z]+$/, "Last name can only contain letters")
    .label("Last name"),
  email: yup.string().email().required().label("Email"),
  password: yup.string().min(6).max(20).label("Password"),
  confirmPassword: yup.string().min(6).max(20).label("Confirm password"),
});

export const SignupForm = (props: StackProps) => {
  const [isAgreed, setIsAgreed] = useState(true);
  const [verified, setVerified] = useState(false);
  const { signupMutation } = useAuthForm();
  const methods = useForm<SignupFormTypes>({ resolver: yupResolver(schema) });

  const getDisabledStatus = () => {
    //Removed isAgreed as requested to login without checking checkbox
    if (!!verified) return false;
    return true;
  };

  if (!!signupMutation.isSuccess)
    return (
      <Center>
        <FormAlert
          title="Verify your account"
          description="Check your email, after clicking on link you will be redirected to verification page."
        />
      </Center>
    );
  return (
    <Stack spacing="6" py={6} {...props}>
      <FormHeader
        title="Create an account"
        description="Already have an account?"
        link={{
          label: "Sign in",
          to: ROUTES.LOGIN_IN,
        }}
      />
      <RoleRadioButtons
        link="https://staging-talent.vercel.app/signup" // TODO: Change this to production urls
      />
      <FormProvider {...methods}>
        <FormWrapper
          onSubmit={methods.handleSubmit((data) => signupMutation.mutate(data))}
        >
          <Stack spacing="5">
            <FormInputField
              name="firstName"
              label="First Name"
              placeholder="e.g., John"
            />
            <FormInputField
              name="lastName"
              label="Last Name"
              placeholder="e.g., Doe"
            />
            <FormInputField
              type="email"
              name="email"
              label="Email"
              placeholder="e.g., john@email.com"
            />
            <FormInputField
              name="password"
              type="password"
              label="Password"
              placeholder="Enter your password"
            />
            <FormInputField
              name="confirmPassword"
              type="password"
              label="Confirm Password"
              placeholder="Confirm your password"
            />
          </Stack>

          <ConsentCheckbox isChecked={isAgreed} onChange={setIsAgreed} />
          <TurnTileWidget setVerified={setVerified} />
          <Stack spacing="4">
            <Button
              variant="primary"
              color="white"
              bg="blue.500"
              isDisabled={getDisabledStatus()}
              _hover={{ backgroundColor: "blue.600" }}
              type="submit"
              isLoading={signupMutation.isPending}
            >
              Sign up
            </Button>
            <Button
              variant="secondary"
              border="1px"
              isDisabled={getDisabledStatus()}
              borderColor="gray.200"
              leftIcon={<GoogleIcon boxSize="5" />}
              iconSpacing="3"
              onClick={() => {
                window.open(`${API_URL}/auth/google`, "_self");
              }}
            >
              Sign up with Google
            </Button>
          </Stack>
        </FormWrapper>
      </FormProvider>
    </Stack>
  );
};
