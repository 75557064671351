/////////////////////
// Help Section (Dashboard)
/////////////////////

// Basic Imports

// Design Imports
import {
  Circle,
  HStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  Box,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import { BsQuestionCircleFill, BsLifePreserver } from "react-icons/bs";

// Layout and Section Imports
import PageContent from "components/ui/appPages/PageContent";

// Data Imports
import { helpPageData } from "data/helpPageData";

// Interfaces

// Functions
const HelpSection = () => {
  return (
    <PageContent id="PageContent">
      <HStack
        id="HStack"
        color={mode("gray.500", "gray.400")}
        pb="4"
        width="100%"
      >
        <Circle bg="orange.400" color="white" size="6">
          <BsLifePreserver />
        </Circle>
        <Text fontWeight="medium">Help Topics for Subscribers</Text>
      </HStack>
      {/* Accordion section for help page */}
      <Accordion allowToggle>
        {helpPageData.map((item, idx) => {
          return (
            <AccordionItem
              key={idx.toString()}
              bg={idx === 0 || idx % 2 === 0 ? "gray.200" : ""}
              borderRadius="md"
            >
              <h2>
                <AccordionButton>
                  <BsQuestionCircleFill />
                  <Box
                    flex="1"
                    textAlign="left"
                    fontWeight="bold"
                    fontSize="sm"
                    color="black"
                    p={2}
                  >
                    {item?.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>{item?.description}</AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </PageContent>
  );
};

export default HelpSection;
