/////////////////////
// App Layout
/////////////////////

// Basic Imports
import { FC } from "react";
import { Outlet } from "react-router-dom";

// Design Imports
import { Box } from "@chakra-ui/react";

import Header from "components/ui/appMenu/Header";
import { INotificationDataState } from "hooks/useSocket";

// Layout and Section Imports

// Data Imports

// Custom Hooks and Services

// Interfaces
interface Props {
  onDeleteNotification: (id: string | string[]) => void;
  notifications: {
    notificationData: INotificationDataState;
    isConnected: boolean;
  };
}

// Functions
const AppLayout: FC<Props> = ({ notifications, onDeleteNotification }) => {
  return (
    <Box minH="100vh" overflowX="hidden">
      <Header
        onDeleteNotification={onDeleteNotification}
        notifications={notifications}
      />
      <Outlet />
    </Box>
  );
};

export default AppLayout;
